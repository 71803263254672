import React from 'react';
import {getCustomersByUser, updateCustomer} from '../actions/strapi';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import {history} from '../routers/AppRouter';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Cookies from "js-cookie";

   
const theme = createTheme({
});


async function getData (customers, data) {
  data.qc = customers.reduce((count, current)=> {
    if(current["qc"]){
      count ++ 
    }
    return count
  }, 0)
  data.contacted = customers.reduce((count, current)=> {
    if(current["contacted"]){
      count ++ 
    }
    return count
  }, 0)
  data.appset = customers.reduce((count, current)=> {
    if(current["appset"]){
      count ++ 
    }
    return count
  }, 0)
  data.appshown = customers.reduce((count, current)=> {
    if(current["appshown"]){
      count ++ 
    }
    return count
  }, 0)
  data.referrals = customers.reduce((count, current)=> {
    if(current["referrals"]){
      count ++ 
    }
    return count
  }, 0)
  data.sold = customers.reduce((count, current)=> {
    if(current["sold"]){
      count ++ 
    }
    return count
  }, 0)
  return data
}

function desc(a, b, orderBy) {
  if(orderBy == 'name') {
    return a[orderBy].toLowerCase().localeCompare(b[orderBy].toLowerCase())
  }
  else {
    if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
  }
 
  
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  
}

const rows = [
  { id: 'nameHead', numeric: false, disablePadding: false, label: 'Customer Name' },
  { id: 'contactedHead', numeric: true, disablePadding: false, label: 'Contacted' },
  { id: 'qcHead', numeric: true, disablePadding: false, label: 'Quality Conversations' },
  { id: 'appsetHead', numeric: true, disablePadding: false, label: 'Appt Set' },
  { id: 'appshownHead', numeric: true, disablePadding: false, label: 'Appt Shown' },
  { id: 'referralHead', numeric: true, disablePadding: false, label: 'Referrals' },
  { id: 'soldHead', numeric: true, disablePadding: false, label: 'Sold' },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    
    const {  order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'center' : 'left'}
                padding={row.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === row.id ? order : false}
              >
                
               
                    {row.label}
               
            
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}


class EnhancedTableToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    }
  }

  handleToggle = () => {
   history.push('/addcustomer') 
  };

 
 render() {
  const team = Cookies.get("team")
  return (

    <Toolbar>
      <div className="tabletoolbar">
      <div className="left-toolbar">
          <Typography variant="h6" id="tableTitle">
            Customers
          </Typography>
        </div>
        <div className="right-toolbar">
        <Button
            onClick={this.handleToggle}
            disabled={(team === "undefined")}
            className="addcustomer-button"
          >Add Customer</Button>
          </div>
        
      </div>
    </Toolbar>
  );
 }
};


class EnhancedTable extends React.Component {
  state = {
    order: 'desc',
    orderBy: 'name',
    selected: {},
    customers: [],
    data: {
      contacted: 0,
      qc: 0,
      appset: 0,
      appshown: 0,
      referrals: 0,
      sold: 0
    },
    page: 0,
    rowsPerPage: 10,
    updateCustomer: false,
    index: -1,
    nameChange: ''
  };
  async componentDidMount() {
   const customers = await getCustomersByUser();
 
   const { data} = this.state;
   const newData = getData(customers, data);
    this.setState({ 'customers': customers, newData })
  }
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };


  handleClick = (event, id, index) => {
    const {updateCustomer, customers, page, rowsPerPage} = this.state;
    const newCustomers = customers;
    const trueIndex = customers.map(function(customer) { return customer.id; }).indexOf(id);
    const newUpdate = !updateCustomer;
    const newSelected = customers[trueIndex]
    this.setState({ selected:newSelected, updateCustomer:newUpdate,trueIndex  });

    document.getElementById('customerName').value = newSelected.name;
  };

  handleClose = () => {
    this.setState({ updateCustomer:false, nameChange: ''});
  };
  handleUpdate = () => {
    const {nameChange, selected} = this.state;
    selected.name = nameChange;
    this.setState({ selected, updateCustomer:false, nameChange: ''})
    updateCustomer(selected.id, selected)
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };
  isSelectedqc = (id, index) => {
    
      if(this.state.customers[index].qc){
      return true
      }
      else{
      return false
      }
    
  };
  isSelectedcontacted = (id, index) => {
      if(this.state.customers[index].contacted){
      return true
      }
      else{
      return false
      }
    
  };
  isSelectedappset = (id, index) => {
    if(this.state.customers[index].contacted){
    return true
    }
    else{
    return false
    }
  }
  isSelectedappshown = (id, index) => {
    if(this.state.customers[index].contacted){
    return true
    }
    else{
    return false
    }
  }
  

  isSelectedreferral(id, index)   {
   return 
  };

  async onCheckChange(propertyName, index, event) {
    const { customers, page, rowsPerPage, data} = this.state;
    const currentIndex = index + (page * rowsPerPage);
    customers[currentIndex][propertyName] = event.target.checked;
    const newData = await getData(customers, data);
    this.setState({ customers, data:newData});
    updateCustomer(customers[currentIndex].id, customers[currentIndex]);
  }
  onReferralChange(propertyName, index,event) {
    const { customers, page, rowsPerPage, data} = this.state;
    const currentIndex = index + (page * rowsPerPage);
  if(event.target.value <= 0){
    customers[currentIndex].referrals = 0
  }
  else if(event.target.value >= 10){
    customers[currentIndex].referrals = 10
  }
  else{
    customers[currentIndex].referrals = event.target.value;
  }
    
    this.setState({ customers });
    updateCustomer(customers[currentIndex].id, customers[currentIndex]);
  }
  onChange(event) {
    let { nameChange } = this.state;
    nameChange = event.target.value;
    this.setState({ nameChange });
  }
  
 


  render() {
    const { customers, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, customers.length - page * rowsPerPage);

    return (
      <div className="dashboard-bg">
      <div className="dashboard-container">
      <div className="updateCustomerPopup"  style={this.state.updateCustomer ? {}:{display:'none'} }>
      <ThemeProvider theme={theme}>
      <div className="form-wrapper-popup">
      <div className="form-container-popup">
           <TextField
            id="customerName"
             label="Customer Name"
             defaultValue={this.state.selected.name}
             onChange={this.onChange.bind(this)}
             autoFocus={true}
             className="text-field"
             />
      <Button onClick={this.handleClose}>Close</Button>
      <Button onClick={this.handleUpdate} disabled={this.state.nameChange == '' ? true : false}>Update</Button>
      </div>
      </div>
      </ThemeProvider>
      </div>
      {/* <div><h1 className="dashboard-header">MY DASHBOARD</h1></div> */}
      <div className="datacards-container">
      <Card className="datacards">
      <CardContent>
        <div className="card-title">Quality Conversations</div><br />
        <span className="dashboard-numbers">{this.state.data.qc}</span>
        </CardContent>
        </Card>
        <Card className="datacards">
      <CardContent>
        <div className="card-title">Appointments Set</div><br />
        <span className="dashboard-numbers">{this.state.data.appset}</span>
        </CardContent>
        </Card>
        <Card className="datacards">
      <CardContent>
         <div className="card-title">Appointments Shown</div><br />
        <span className="dashboard-numbers">{this.state.data.appshown}</span>
        </CardContent>
        </Card>
        <Card className="datacards">
      <CardContent>
        <div className="card-title">Sold</div><br />
        <span className="dashboard-numbers">{this.state.data.sold}</span>
        </CardContent>
        </Card>
      </div>
      <ThemeProvider theme={theme}>
      <Paper className="paper-container">
        <EnhancedTableToolbar />
        <div >
      
          <Table >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={customers.length}
            />
            <TableBody>
              {stableSort(customers, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n, index) => {
                  const isSelectedcontacted = this.onCheckChange.bind(this,"contacted", index);
                  const isSelectedqc = this.onCheckChange.bind(this,"qc", index);
                  const isSelectedappset = this.onCheckChange.bind(this,"appset", index);
                  const isSelectedappshown = this.onCheckChange.bind(this,"appshown", index);
                  const isSelectedreferral = this.onReferralChange.bind(this,"referrals", index);
                  const isSelectedsold = this.onCheckChange.bind(this,"sold", index);
                
                  return (
                  
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={n.id}
                      className="dashboardTableRow"
                    >
                      <TableCell component="th" scope="row" className="customerNameCell" onClick={event => this.handleClick(event, n.id, index)}>
                        {n.name}
                      </TableCell>
                      <TableCell align="center"><Checkbox 
             onChange={isSelectedcontacted}
             checked={n.contacted} /></TableCell>
                      <TableCell align="center"><Checkbox 
             onChange={isSelectedqc}
             checked={n.qc} /></TableCell>
                      <TableCell align="center"><Checkbox 
             onChange={isSelectedappset}
             checked={n.appset} /></TableCell>
                      <TableCell align="center"><Checkbox 
             onChange={isSelectedappshown}
             checked={n.appshown} /></TableCell>
              
                 <TableCell align="center"><TextField 
                style={{width:70}}
                type="number"
                id="standard-number"
             onChange={isSelectedreferral}
             value={n.referrals} /></TableCell>
             
          
                      <TableCell align="center"><Checkbox 
             onChange={isSelectedsold}
             checked={n.sold} /></TableCell>
                    </TableRow>
                  
                  );
                })}
                
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            
          </Table>
         
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={customers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </Paper>
      </ThemeProvider>
      </div>
      </div>
    );
  }
}



const DashboardPage = () => (
  <div>
    <EnhancedTable />
  </div>
);



export default DashboardPage;
