import React from 'react';
import {createCustomer} from '../actions/strapi';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {history} from '../routers/AppRouter';
import Button from '@mui/material/Button';

const theme = createTheme({
});


class AddCustomer extends React.Component {
constructor(props) {
  super(props);
  this.state = {
      data: {
        name: "",
        qc: false,
        appset: false,
        appshown: false,
        sold: false,
        contacted: false,
        referrals: 0
      },
      loading: false,
      error: ""
    };
}

onChange(propertyName, event) {
    const { data } = this.state;
    data[propertyName] = event.target.value;
    this.setState({ data });
  }
  onReferralChange(propertyName, event) {
    const { data } = this.state;
    if(event.target.value <= 0){
      data[propertyName] = 0
    }
    else if(event.target.value >= 10){
      data[propertyName] = 10
    }
    else{
      data[propertyName] = event.target.value;
    }
    
    this.setState({ data });
  }
  onCheckChange(propertyName, event) {
    const { data } = this.state;
    data[propertyName] = event.target.checked;
    this.setState({ data });
  }
async handleClick(e){
   e.preventDefault();
    this.setState({ loading: true });
  try {
    await createCustomer(this.state.data)
    history.push('/dashboard')
    

  } catch (e) {
    alert(e.message);
  }
}
goBack = () => {
  history.push('/dashboard')
}

  render() {
    return (
    <div>
        <ThemeProvider theme={theme}>
        <div>
        <div className="pageHead">
        <Button onClick={this.goBack}>Back</Button>
        </div>
        <form onSubmit={this.handleClick.bind(this)}>
          <div className={"form-container"}>
         <TextField
             className="text-field"
             required={true}
             label="Customer Name"
             value={this.state.data.name}
             onChange={this.onChange.bind(this, "name")}
             />
          
             <br />
             <FormGroup className={"checkboxes"}>
    
             <FormControlLabel control={<Checkbox 
             checked={this.state.data.contacted}
             onChange={this.onCheckChange.bind(this, "contacted")}
             />} label="Contacted" />
            <FormControlLabel control={<Checkbox 
             checked={this.state.data.qc}
             onChange={this.onCheckChange.bind(this, "qc")}
             />} label="Quality Conversation" />
             <FormControlLabel control={ <Checkbox 
             checked={this.state.data.appset}
             onChange={this.onCheckChange.bind(this, "appset")}
             />} label="Appointment Set" />
             <FormControlLabel control={<Checkbox 
             checked={this.state.data.appshown}
             onChange={this.onCheckChange.bind(this, "appshown")}
             />} label="Appointment Shown" />
              <FormControlLabel control={<Checkbox 
             checked={this.state.data.sold}
             onChange={this.onCheckChange.bind(this, "sold")}
             />} label="Sold" />
             <FormControlLabel   style={{width: 100, marginLeft:1}} control={
             <TextField
             style={{marginLeft:1}}
             id="standard-number"
             type="number"
           
             value={this.state.data.referrals}
             onChange={this.onReferralChange.bind(this, "referrals")}
             variant="standard"
            
           /> 
             
            } label="Referrals" />
            
             </FormGroup>
             <br/>
             <Button type="submit" className="submit-button" disabled={this.state.data.customer == '' ? true : false}>Submit</Button>
         </div>
         </form>
         </div>
         </ThemeProvider>
      </div>
      
      );
  }
  
}
export default AddCustomer;
