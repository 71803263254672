import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import auth from '../actions/auth'
import Header from '../components/Header';

export const PrivateRoute = ({
  isAuthenticated,
  component: Component,
  ...rest
}) => {
return (
    <Route {...rest} render={props => {
      if (auth.isAuthenticated())  
        return (
            <div>
        <Header />
        <Component {...props} />
        </div>
        )
      else {
       return <Redirect to="/" />
      }
         
    } 
    }
    />
  );
    }

// const mapStateToProps = (state) => ({
//   isAuthenticated: !!state.auth.username
// });

export default PrivateRoute;
