import React from 'react';
import { getUsersByDealership} from '../actions/strapi';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const qcFunc = (customers) => {
    return customers.filter(customer => customer.qc == true).length
}

const appsetFunc = (customers) => {
    return customers.filter(customer => customer.appset == true).length
}

const appshownFunc = (customers) => {
    return customers.filter(customer => customer.appshown == true).length
}

const soldFunc = (customers) => {
    return customers.filter(customer => customer.sold == true).length
}
const contactFunc = (customers) => {
    return customers.filter(customer => customer.contacted == true).length
}
const referralFunc = (customers) => {
    return customers.filter(customer => customer.referral == true ).length
}

const totalqcFunc =  (users) => {
    const result = users.reduce((count, user) => {
       let newCount = user.customers.filter(customer => customer.qc == true ).length + count
        return newCount;
    }, 0);
    console.log(result);
    return result
}
const totalappsetFunc = (users) => {
    const result = users.reduce((count, user) => {
       let newCount = user.customers.filter(customer => customer.appset == true ).length + count
        return newCount;
    }, 0);
    console.log(result);
    return result
}

const totalappshownFunc = (users) => {
    const result = users.reduce((count, user) => {
       let newCount = user.customers.filter(customer => customer.appshown == true ).length + count
        return newCount;
    }, 0);
    console.log(result);
    return result
}

const totalsoldFunc = (users) => {
    const result = users.reduce((count, user) => {
       let newCount = user.customers.filter(customer => customer.sold == true ).length + count
        return newCount;
    }, 0);
    console.log(result);
    return result
}
const totalcontactFunc = (users) => {
    const result = users.reduce((count, user) => {
       let newCount = user.customers.filter(customer => customer.contacted == true ).length + count
        return newCount;
    }, 0);
    console.log(result);
    return result
}
const totalreferralFunc = (users) => {
    const result = users.reduce((count, user) => {
       let newCount = user.customers.filter(customer => customer.referral == true ).length + count
        return newCount;
    }, 0);
    console.log(result);
    return result
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'contacted', numeric: true, disablePadding: false, label: 'Contacts' },
  { id: 'qc', numeric: true, disablePadding: false, label: 'Quality Conversations' },
  { id: 'appset', numeric: true, disablePadding: false, label: 'Appt Set' },
  { id: 'appshown', numeric: true, disablePadding: false, label: 'Appt Shown' },
  { id: 'referral', numeric: true, disablePadding: false, label: 'Referral' },
  { id: 'sold', numeric: true, disablePadding: false, label: 'Sold' },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {  order, orderBy } = this.props;
  
    return (
      <TableHead>
        <TableRow>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'center' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            )
          )}
        </TableRow>
      </TableHead>
    );
  }
}




class EnhancedTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: 'asc',
            orderBy: 'calories',
            selected: [],
            page: 0
        }
}
  
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };



  render() {
    const { order, orderBy, selected, page } = this.state;
    const emptyRows = this.props.users.length - Math.min(this.props.users.length, this.props.users.length - page * this.props.users.length);

    return (
      <div>
      <Paper className="paper-container">
        <div >
          <Table  aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={this.props.users.length}
            />
            <TableBody>
              {stableSort(this.props.users, getSorting(order, orderBy))
                .slice(page * this.props.users.length, page * this.props.users.length + this.props.users.length)
                .map((n, index) => {
              return (
                    <TableRow
                    key={n.id}
                    tabIndex={-1}
                    >
                      <TableCell component="th" scope="row">{n.firstName} {n.lastName}</TableCell>
                      <TableCell align="center">{contactFunc(n.customers)}</TableCell>
                      <TableCell align="center">{qcFunc(n.customers)}</TableCell>
                      <TableCell align="center">{appsetFunc(n.customers)}</TableCell>
                      <TableCell align="center">{appshownFunc(n.customers)}</TableCell>
                      <TableCell align="center">{referralFunc(n.customers)}</TableCell>
                      <TableCell align="center">{soldFunc(n.customers)}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={this.props.users.length}
          rowsPerPage={this.props.users.length}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </Paper>
      </div>
    );
  }
}

const setActiveUsers = async () => {
        const result = await getUsersByDealership();
        return result;
    }

class ManagerDashboard extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            dealership: "",
            activeDealership: {},
            dealerships: [],
            users: [],
            activeUsers: []
        };
    }

    async componentDidMount() {
      const activeUsers = await setActiveUsers();
      
      this.setState({dealership:activeUsers[0].dealership.name,activeUsers});
    }
    render() {
        return (
            <div>
                <div className="tdashboard-bg">
                    <div className="tdashboard-bg-cover">
                        <div className="select-container">
                        <select name="dealership"  
                                value={this.state.dealership} 
                                onChange={this.handleDealershipChange} 
                                  >
                                {   this.state.dealerships.map((n, index) => {
                                    return (
                                            <option key={n.id}>
                                                {n.name}
                                            </option>
                                        );
                                        }
                                )};
                        </select>
                        </div>
                        <div className="tdashboard-container-dealership">
                            <div className="datacards-container-dealership">
                                <Card className="datacards-dealership">
                                    <CardContent>
                                        <div className="card-title-dealership">Contacts</div><br />
                                        <span className="dashboard-numbers-dealership">{totalcontactFunc(this.state.activeUsers)}</span>
                                    </CardContent>
                                </Card>
                                <Card className="datacards-dealership">
                                    <CardContent>
                                        <div className="card-title-dealership">Quality Conversations</div><br />
                                        <span className="dashboard-numbers-dealership">{totalqcFunc(this.state.activeUsers)}</span>
                                    </CardContent>
                                </Card>
                                    <Card className="datacards-dealership">
                                        <CardContent>
                                            <div className="card-title-dealership">Appointments Set</div><br />
                                            <span className="dashboard-numbers-dealership">{totalappsetFunc(this.state.activeUsers)}</span>
                                        </CardContent>
                                    </Card>
                                <Card className="datacards-dealership">
                                    <CardContent>
                                        <div className="card-title-dealership">Appointments Shown</div><br />
                                        <span className="dashboard-numbers-dealership">{totalappshownFunc(this.state.activeUsers)}</span>
                                    </CardContent>
                                </Card>
                                <Card className="datacards-dealership">
                                    <CardContent>
                                        <div className="card-title-dealership">Referrals</div><br />
                                        <span className="dashboard-numbers-dealership">{totalreferralFunc(this.state.activeUsers)}</span>
                                    </CardContent>
                                </Card>
                                <Card className="datacards-dealership">
                                    <CardContent>
                                        <div className="card-title-dealership">Sold</div><br />
                                        <span className="dashboard-numbers-dealership">{totalsoldFunc(this.state.activeUsers)}</span>
                                    </CardContent>
                                </Card>
                            </div>
                            <EnhancedTable users={this.state.activeUsers}/>
                        </div>
                    </div>
                </div>
            </div>
        )}
    };



export default ManagerDashboard;
