
import React from 'react';
import auth, { unsetToken } from "../actions/auth";
import {ThemeProvider, createTheme} from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import {history} from '../routers/AppRouter'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

const theme = createTheme({
});
class MenuListComposition extends React.Component {
  state = {
    open: false,
  };

   redirectDash = () => {
    history.push('/dashboard')
    this.setState({ open: false });
}
 redirectAdd = () => {
    history.push('/addcustomer')
    this.setState({ open: false });
}
redirectScoreboard = () => {
    history.push('/scoreboard')
    this.setState({ open: false });
}
redirectTeamDash = () => {
    history.push('/teamdashboard')
    this.setState({ open: false });
}
redirectDocuments = () => {
  history.push('/documents')
  this.setState({ open: false });
}
redirectATNVT = () => {
  window.open('https://automotivetrainingnetwork.lightspeedvt.com/', '_blank');
  this.setState({ open: false });
}
redirectPostSurvey = () => {
  window.open('https://atntraining.typeform.com/to/mmBSM9', '_blank');
  this.setState({ open: false });
}
  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;

    return (
      <div className="headerbar">
        <div>
          <Button
            ref={node => {
              this.anchorEl = node;
            }}
            aria-owns={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={this.handleToggle}
            className="menu-button"
          >
            Menu
          </Button>
          <Popper open={open} anchorEl={this.anchorEl} transition disablePortal className="menu-popper">
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList>
                      <MenuItem onClick={this.redirectDash}>My Dashboard</MenuItem>
                      <MenuItem onClick={this.redirectScoreboard}>Scoreboard</MenuItem>
                      <MenuItem onClick={this.redirectTeamDash}>Team Dashboard</MenuItem>
                      <MenuItem onClick={this.redirectDocuments}>Documents</MenuItem>
                      <MenuItem onClick={this.redirectATNVT}>ATNVT</MenuItem>
                      <MenuItem onClick={this.redirectPostSurvey}>Post-TNP Survey</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    );
  }
}
 const Header = () => {

 return(
    <header>
      <div>
        <ThemeProvider theme={theme}>
          <div>
           <AppBar position="static" className="menu-appbar">
        <Toolbar className="menu-toolbar">
        { !!auth.isAuthenticated() ?
            <div className="menu-container">
            <div className="menu-left">
            <MenuListComposition />
            </div>
            <div className="menu-right">
            {/* <Button color="inherit" onClick={redirectAdd}>Add Customer</Button>
            <Button color="inherit" onClick={redirectDash}>Dashboard</Button> */}
            <Button color="inherit" onClick={unsetToken}>Logout</Button>
            </div>
            </div>
            :
            <Button color="inherit" 
            // onClick="history.push('/')"
            >Login</Button>
        }
        </Toolbar>
      </AppBar>
         </div>
         </ThemeProvider>
      </div>
    </header>
  )};
  
  export default Header