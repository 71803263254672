import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import {history} from '../routers/AppRouter';
import axios from 'axios';
import qs from 'qs';
import {parseString} from 'xml2js';
import{getCurrentCamp} from './strapi'
const NODE_ENV = process.env.NODE_ENV;





const apiUrl = process.env.API_URL || "https://tnp-strapi.herokuapp.com/api";


// export const strapiRegister = (username, email, password) => {
//   if (!process.browser) {
//     return undefined;
//   }
//   instance.post('/auth/local', {
  
//     data: {
//       identifier: username,
//       password: password
//       }
// }).then(res => {
//     setToken(res.jwt);
//   });
//   return Promise.resolve();
// };
//use strapi to get a JWT and token object, save
//to approriate cookie for future requests



export const strapiLogin = async (email, password) => {
  if (typeof window === 'undefined') {
    return;
  }
  
  try{

   const res = await axios.post('https://tnp-strapi.herokuapp.com/api/auth/local',
    {
      identifier: email.toLowerCase(),
      password: password
      }
      )
      setToken(res.data)
    return res
  }
  catch (e){
    console.log(e.message)
    return e
  }
};

export const setToken = async (token) => {
  if (typeof window === 'undefined') {
    return;
  }
  const {bootcamp, team} = await getCurrentCamp(token.user.id, token.jwt);
  Cookies.set("bootcamp", bootcamp, { expires: 1 })
  Cookies.set("team", team, { expires: 1 });
  Cookies.set("username", token.user.username, { expires: 1 });
  Cookies.set("user", JSON.stringify(token.user), { expires: 1 })
  Cookies.set("jwt", token.jwt, { expires: 1 });


  if (Cookies.get("username")) {
    history.push('/dashboard')
    
  }
  return Promise.resolve();
};

export const unsetToken = () => {
  if (typeof window === 'undefined') {
    return;
  }
  Cookies.remove("jwt");
  Cookies.remove("username");
  Cookies.remove("user");
  Cookies.remove("lsvt-token");
  Cookies.remove("team");
  Cookies.remove("bootcamp");


  // to support logging out from all windows
  window.localStorage.setItem("logout", Date.now());
  history.push('/')
};

export const getUserFromServerCookie = req => {
  if (!req.headers.cookie || "") {
    return undefined;
  }

  let username = req.headers.cookie
    .split(";")
    .find(user => user.trim().startsWith("username="));
  if (username) {
    username = username.split("=")[1];
  }

  const jwtCookie = req.headers.cookie
    .split(";")
    .find(c => c.trim().startsWith("jwt="));
  if (!jwtCookie) {
    return undefined;
  }
  const jwt = jwtCookie.split("=")[1];
  return jwtDecode(jwt)
  // , username;
};

export const getUserFromLocalCookie = () => {
  return Cookies.get("username");
};

//these will be used if you expand to a provider such as Auth0
const getQueryParams = () => {
  const params = {};
  window.location.href.replace(
    /([^(?|#)=&]+)(=([^&]*))?/g,
    ($0, $1, $2, $3) => {
      params[$1] = $3;
    }
  );
  return params;
};
export const extractInfoFromHash = () => {
  if (typeof window === 'undefined') {
    return undefined;
  }
  const {
    id_token,
    state
  } = getQueryParams();
  return {
    token: id_token,
    secret: state
  };
};

 class Auth {
  constructor() {
      this.authenticated = false
  }
  login(email, password){
    this.authenticated = true
    fetchToken()
    return strapiLogin(email, password)
  }
  logout(){
    this.authenticated = false
  }

  isAuthenticated() {
    this.authenticated = !!getUserFromLocalCookie();
    return this.authenticated
  }

}

export const fetchToken = async () => {
  let newToken;
  // const instance = axios.create({
  //   baseURL: 'https://webservices.lightspeedvt.net/lsvt_api_v35.ashx',
  //   headers: {
  //     'Content-Type': 'application/x-www-form-urlencoded'
  //   }
  // });
  // const requestBody = {
  //   authkey: '30A999EB'
  // };
  // const requestbody = qs.stringify(requestBody);
  // await instance.post('?command=getLGToken', requestbody)
  //   .then(async (result) => {
  //     await parseString(result.data, function (err, parsed) {
  //       newToken = parsed.lsvt_apiResult.data[0]._;
  //       Cookies.set("lsvt-token", newToken, { expires: 1 });
  //     });
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });
  return newToken;
}

export const isUsernameAvailable = async (login) => {
  let usernameStatus;
  const token = await Cookies.get("lsvt-token")
  const instance = axios.create({
    baseURL: 'https://webservices.lightspeedvt.net/lsvt_api_v35.ashx',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
  const requestBody = {
    authkey: '30A999EB',
    token,
    login
  };
  const requestbody = qs.stringify(requestBody);
  await instance.post('?command=checkLoginName', requestbody)
    .then(async (result) => {
      await parseString(result.data, function (err, parsed) {
        if(parsed.lsvt_apiResult.result[0] == "true"){
          usernameStatus = false
        }
        else{
          usernameStatus = true
        }
      });
    })
    .catch((err) => {
      console.log(err);
    });
  return usernameStatus;
}

export default new Auth();
