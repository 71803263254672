import React from 'react';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent'; 
import {forgotPassword} from '../actions/strapi';
import {history} from '../routers/AppRouter'

const theme = createTheme({
});
const ErrorMessage = (props) => (
<div className='error-message'>
     {props.error}
  </div>
)

class ForgotPassword extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
      data: {
       email: ""
      },
      loading: false,
      error: "",
      errorView: false,
      successView: false,
      emailSent: false
    };
  }
  componentDidMount() {

  }
  toggleError(error) {
    console.log(error)
      let errorText;
    if(typeof error.message !=  "undefined"){
      if(error.message.includes('Incorrect params provided')){
        errorText = 'Failed to reset'
    }
    else if(error.message.includes('email does not exist') || error.message.includes('email must be a valid email')){
        errorText = 'No user with that email found'
    }
    else {
      errorText = 'Unknown Error Occurred';
  }
  }
    else {
        errorText = 'Unknown Error Occurred';
    }
    this.setState({ error: errorText, errorView: true })
    }

  onChange(propertyName, event) {
    const { data } = this.state;
    data[propertyName] = event.target.value;
    this.setState({ data });
  }
  async onSubmit(e) {
    e.preventDefault();
    const {
      data: { email }
    } = this.state;
    this.setState({ loading: true, errorView: false });
  try {
      await forgotPassword(email);
       this.setState({ emailSent: true  });
      
  
  } catch (e) {
   
    this.toggleError(e.response.data.error);
  }
  }
  onBack(){
      history.push('/');
      }


 
render() {
    return (
        <div>
        <form onSubmit={this.onSubmit.bind(this)}>
        <ThemeProvider theme={theme}>
        <div className="login-container">
        { this.state.emailSent ? 
            <Card className="login-box">
            <CardContent>
        <div>Email Sent. Check your inbox for the reset link.</div>
        </CardContent>
        </Card>
        :
          <Card className="login-box">
            <CardContent>
            <h2 className="login-title">Forgot Password</h2>
            {this.state.errorView && <ErrorMessage error={this.state.error} />}
            <TextField
                required={true}
                label="Email"
              
                onChange={this.onChange.bind(this, "email")}
                className="text-field"
                />
            
             <br/>
             <Button label="Submit" className="submit-button" type="submit">Submit</Button>
             <br/>
             <Button label="Back" className="back-button" onClick={this.onBack}>Cancel</Button>
             </CardContent>
            </Card>
            }
         </div>
         </ThemeProvider>
         </form>
      </div>
    );
  }
}
export default ForgotPassword;