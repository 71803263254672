import React from 'react';
import {getCustomersByBootcamp} from '../actions/strapi';
async function getData (customers, data) {
   
    let qcCount = 0;
let contactedCount = 0;
let appsetCount = 0;
let appshownCount = 0;
let referralCount = 0;
let soldCount = 0;
let score = 0;
    for(let i = 0; i < customers.length; i++) {
       let current = customers[i];
       if(current["qc"] == true){
            qcCount += 1
        }
        if(current["contacted"]){
            contactedCount += 1
        }
        if(current["appset"]){
            appsetCount += 1
        }
        if(current["appshown"]){
            appshownCount += 1
        }
        if(current["referrals"]){
            referralCount += 1 * current["referrals"]
        }
        if(current["sold"]){
            soldCount += 1
        }
    }
    data.qc = qcCount;
    data.contacted = contactedCount;
    data.appset = appsetCount;
    data.appshown = appshownCount;
    data.referral = referralCount;
    data.sold = soldCount;
for (let i = 0; i < customers.length; i++){
    let current = customers[i];
    if(current["qc"]){
      score += 1
    }
     if(current["appset"]){
      score += 2
    }
     if(current["appshown"]){
      score += 3
    }
     if(current["sold"]){
      score += 5
    }
    if(current["referrals"]){
        score += 2 * current["referrals"]
    }
}
data.score = score;
  return data
}

class Scoreboard extends React.Component {
    _isMounted = false;
    constructor(props){
        super(props)
   
        this.state = {
            black: {
                color: 'black',
                positionClass: "position-1",
                position: 1,
                qc: 0,
                appset: 0,
                appshown: 0,
                referral: 0,
                sold: 0,
                score: 0
            },
            blue: {
                color: 'blue',
                positionClass: "position-2",
                position: 2,
                qc: 0,
                appset: 0,
                appshown: 0,
                referral: 0,
                sold: 0,
                score: 0
            },
            red: {  
                color: 'red',
                positionClass: "position-3",
                position: 3,
                qc: 0,
                appset: 0,
                appshown: 0,
                referral: 0, 
                sold: 0,
                score: 0
            },
            green:{
                color: 'green',
                positionClass: "position-4",
                position: 4,
                qc: 0,
                appset: 0,
                appshown: 0,
                referral: 0,
                sold: 0,
                score: 0
            }
        }
    }

     componentDidMount() {
          try {
            this._isMounted = true;
             const getCustomers = async() => {
                    setTimeout(() => { 
                        if(this._isMounted) {
                         getCustomers()
                        }
                        },30000) 
                 const {black, red, green, blue} = this.state;
                const customers = await getCustomersByBootcamp();
                const blueCustomers = await getData(customers.filter(customer => customer.teamColor == 'blue'), blue);
                const redCustomers = await getData(customers.filter(customer => customer.teamColor == 'red'), red);
                const blackCustomers = await getData(customers.filter(customer => customer.teamColor == 'black'), black);
                const greenCustomers = await getData(customers.filter(customer => customer.teamColor == 'green'), green);
                const position = [blackCustomers, blueCustomers, redCustomers, greenCustomers];
                await position.sort((a, b) => b.score - a.score);
                position.forEach((team, index) => {
                    if (team.color == 'green') {
                        greenCustomers.position = index + 1;
                        greenCustomers.positionClass = `position-${index + 1}`;
                        this.setState({green:greenCustomers})
                    }
                    else if(team.color == 'black') {
                        blackCustomers.position = index + 1;
                        blackCustomers.positionClass = `position-${index + 1}`;
                        this.setState({black:blackCustomers})
                    }
                    else if(team.color == 'red') {
                        redCustomers.position = index + 1;
                        redCustomers.positionClass = `position-${index + 1}`;
                        this.setState({red:redCustomers})
                    }
                    else if(team.color == 'blue') {
                        blueCustomers.position = index + 1;
                        blueCustomers.positionClass = `position-${index + 1}`;
                        this.setState({blue:blueCustomers})
                    }
                })
                
             }
            getCustomers()
          } catch(e) {
            console.log(e);
          }
    }
    componentWillUnmount(){
        this._isMounted = false;
    }


    render() {
        return(
            <div>
                <div className="scoreboard-cards">
               <div className="card blue-team" id={this.state.blue.positionClass}>
                <div className="card-container">
                    <h3 className="team-title">Blue Team</h3>
                    <div className="number-container"> 
                        <div className="sb-numbers">
                            <span className="main-number">{this.state.blue.qc}</span>
                            <div className="sb-label">QC</div>
                        </div>
                        <div className="sb-numbers">
                            <span className="main-number">{this.state.blue.appset}</span>
                            <div className="sb-label">Set</div>
                        </div>
                        <div className="sb-numbers">
                        <span className="main-number">{this.state.blue.appshown}</span>
                            <div className="sb-label">Shown</div>
                        </div>
                        <div className="sb-numbers">
                        <span className="main-number">{this.state.blue.referral}</span>
                            <div className="sb-label">Referral</div>
                        </div>
                        <div className="sb-numbers">
                        <span className="main-number">{this.state.blue.sold}</span>
                            <div className="sb-label">Sold</div>
                        </div>
                        <div className="sb-numbers scorebox">
                            <span className="main-number score">{this.state.blue.score}</span>
                            <div className="sb-label">Score</div>
                        </div>
                        </div>
                    </div>
               </div>
               <div className="card red-team" id={this.state.red.positionClass}>
               <div className="card-container">
                    <h3 className="team-title">Red Team</h3>
                    <div className="number-container"> 
                        <div className="sb-numbers">
                            <span className="main-number">{this.state.red.qc}</span>
                            <div className="sb-label">QC</div>
                        </div>
                        <div className="sb-numbers">
                            <span className="main-number">{this.state.red.appset}</span>
                            <div className="sb-label">Set</div>
                        </div>
                        <div className="sb-numbers">
                        <span className="main-number">{this.state.red.appshown}</span>
                            <div className="sb-label">Shown</div>
                        </div>
                         <div className="sb-numbers">
                        <span className="main-number">{this.state.red.referral}</span>
                            <div className="sb-label">Referral</div>
                        </div>
                        <div className="sb-numbers">
                        <span className="main-number">{this.state.red.sold}</span>
                            <div className="sb-label">Sold</div>
                        </div>
                        <div className="sb-numbers scorebox">
                            <span className="main-number score">{this.state.red.score}</span>
                            <div className="sb-label">Score</div>
                        </div>
                        </div>
                    </div>
               </div>
               <div className="card green-team" id={this.state.green.positionClass}>
               <div className="card-container">
                    <h3 className="team-title">Green Team</h3>
                    <div className="number-container"> 
                        <div className="sb-numbers">
                            <span className="main-number">{this.state.green.qc}</span>
                            <div className="sb-label">QC</div>
                        </div>
                        <div className="sb-numbers">
                            <span className="main-number">{this.state.green.appset}</span>
                            <div className="sb-label">Set</div>
                        </div>
                        <div className="sb-numbers">
                        <span className="main-number">{this.state.green.appshown}</span>
                            <div className="sb-label">Shown</div>
                        </div>
                        <div className="sb-numbers">
                        <span className="main-number">{this.state.green.referral}</span>
                            <div className="sb-label">Referral</div>
                        </div>
                        <div className="sb-numbers">
                        <span className="main-number">{this.state.green.sold}</span>
                            <div className="sb-label">Sold</div>
                        </div>
                        <div className="sb-numbers scorebox">
                            <span className="main-number score">{this.state.green.score}</span>
                            <div className="sb-label">Score</div>
                        </div>
                        </div>
                    </div>
               </div>
               <div className="card black-team" id={this.state.black.positionClass}>
               <div className="card-container">
                    <h3 className="team-title">Black Team</h3>
                    <div className="number-container"> 
                        <div className="sb-numbers">
                            <span className="main-number">{this.state.black.qc}</span>
                            <div className="sb-label">QC</div>
                        </div>
                        <div className="sb-numbers">
                            <span className="main-number">{this.state.black.appset}</span>
                            <div className="sb-label">Set</div>
                        </div>
                        <div className="sb-numbers">
                        <span className="main-number">{this.state.black.appshown}</span>
                            <div className="sb-label">Shown</div>
                        </div>
                        <div className="sb-numbers">
                        <span className="main-number">{this.state.black.referral}</span>
                            <div className="sb-label">Referral</div>
                        </div>
                        <div className="sb-numbers">
                        <span className="main-number">{this.state.black.sold}</span>
                            <div className="sb-label">Sold</div>
                        </div>
                        <div className="sb-numbers scorebox">
                        <span className="main-number score">{this.state.black.score}</span>
                            <div className="sb-label">Score</div>
                        </div>
                        </div>
                    </div>
               </div>
               </div>
            </div>
        )
    }

}


export default Scoreboard;