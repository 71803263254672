import React from 'react';
import {getCustomersByBootcamp, getUsers} from '../actions/strapi';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { Triangle } from  'react-loader-spinner';
import { Timer, Time, TimerOptions } from 'timer-node';
import { ThemeProvider, createTheme } from '@mui/material/styles';


function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


const qcFunc = (customers) => {
    return customers.filter(customer => customer.qc == true).length
}

const appsetFunc = (customers) => {
    return customers.filter(customer => customer.appset == true).length
}

const appshownFunc = (customers) => {
    return customers.filter(customer => customer.appshown == true).length
}

const soldFunc = (customers) => {
    return customers.filter(customer => customer.sold == true).length
}
const contactFunc = (customers) => {
    return customers.filter(customer => customer.contacted == true).length
}
const add = (accumulator, a) => {
  return accumulator + a.referrals;
}
const referralFunc = (customers) => {

  return customers.reduce(add, 0);
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'contacted', numeric: true, disablePadding: false, label: 'Contacts' },
  { id: 'qc', numeric: true, disablePadding: false, label: 'Quality Conv' },
  { id: 'appset', numeric: true, disablePadding: false, label: 'Appt Set' },
  { id: 'appshown', numeric: true, disablePadding: false, label: 'Appt Shown' },
  { id: 'referrals', numeric: true, disablePadding: false, label: 'Referrals' },
  { id: 'sold', numeric: true, disablePadding: false, label: 'Sold' },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {  order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'center' : 'left'}
                padding={row.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === row.id ? order : false}
              >
                    {row.label}
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}




class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);
 
    this.state = {
      order: 'asc',
      orderBy: 'calories',
      selected: [],
      page: 0,
      rowsPerPage: 5,
    };
  }
  static getDerivedStateFromProps(props, state) {
    if(props.props.users !== state.users){
    return {
      users: props.props.users
    }
  }
  return null;
}
  
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleClick = (event, id) => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.users.map(n => n.id) }));
      return;
    }
    this.setState({ users: [] });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };



  render() {
    const {  users,order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

    return (
      <div className={`team-table-${this.props.props.color}`}>
      <Paper className="paper-container">
        <div >
          <Table  aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={users.length}
            />
            <TableBody   sx={{
    "& tr:nth-of-type(2n)": {
      backgroundColor: "rgba(255,255,255,0.1)",
    },
  }}>
              {stableSort(users, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n, index) => {
              return (
                    <TableRow
                    key={n.id}
                      role="checkbox"
                      tabIndex={-1}
                    >
                      <TableCell component="th" className="name-cell" scope="row"  style={{ width: "22" }}>{n.firstName} {n.lastName}</TableCell>
                      <TableCell align="center" className="contact-cell"  style={{ width: "13%"}}>{contactFunc(n.customers)}</TableCell>
                      <TableCell align="center" className="qc-cell" style={{ width: "13%" }} >{qcFunc(n.customers)}</TableCell>
                      <TableCell align="center" className="appset-cell" style={{ width: "13%" }}>{appsetFunc(n.customers)}</TableCell>
                      <TableCell align="center" className="appshown-cell" style={{ width: "13%"}}>{appshownFunc(n.customers)}</TableCell>
                        <TableCell align="center" className="referral-cell" style={{ width: "13%"}}>{referralFunc(n.customers)}</TableCell>
                      <TableCell align="center" className="sold-cell" style={{ width: "13%"}}>{soldFunc(n.customers)}</TableCell>
                    </TableRow>
                  );
                })}
            
            </TableBody>
          </Table>
        </div>

      </Paper>
      </div>
    );
  }
}

class TeamDashboard extends React.Component {

constructor(props){
  super(props);
  this.state={
    loading:true,
    teams:[]
  };
}

async componentDidMount() {
  const getCustomers = async() => {
    const timer = new Timer({ label: 'timer' });
    timer.start()
    setTimeout(() => { 
    
       getCustomers()
      
      },30000);

  const users = await getUsers();
  const customers = await getCustomersByBootcamp();
  const teams = [];
  teams.push({color: "Blue",
  users: users.blueTeam.map(obj => ({ ...obj, customers: customers.filter(a => a.user.data.id === obj.id) }))})
  teams.push({color: "Green",
  users: users.greenTeam.map(obj => ({ ...obj, customers: customers.filter(a => a.user.data.id === obj.id) }))})
  teams.push({color: "Red",
  users: users.redTeam.map(obj => ({ ...obj, customers: customers.filter(a => a.user.data.id === obj.id) }))})
  teams.push({color: "Black",
  users: users.blackTeam.map(obj => ({ ...obj, customers: customers.filter(a => a.user.data.id === obj.id) }))})
this.setState({teams})
if (timer.time().ms <= 1000){
  await timeout(1000-timer.time().ms);
  timer.stop();
  this.setState({loading:false})
}
else{
  this.setState({loading:false})
}

}
getCustomers()
}


render() {
 
  if(this.state.loading){
      return(<div className="spinner-box"> <Triangle
  height="80"
  width="80"
  color="#930000"
  ariaLabel="triangle-loading"
  wrapperClass="triangle-loader"
  visible={this.state.loading}
/></div>)
    }
    else {
  
      return (  <div className="tdashboard-bg">
      <div className="tdashboard-container">
        {this.state.teams.map((team, index) => {
          if(team.users.length > 0){
            return  <EnhancedTable  key={index} props={team} />}})}
    
        </div>
      </div> );
    }
  
 
  
}
}




export default TeamDashboard;
