import './App.scss';
import React from 'react';
import AppRouter from './routers/AppRouter';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';

export const store = configureStore();

function App() {
  return (
    <div className="App">
    <Provider store={store}>
      <AppRouter />
      </Provider>
    </div>
  );
}

export default App;
