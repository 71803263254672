import React from 'react';
import {getCustomersByBootcamp, getUsers} from '../actions/strapi';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';

const qcFunc = (customers) => {
    return customers.filter(customer => customer.qc == true).length
}

const appsetFunc = (customers) => {
    return customers.filter(customer => customer.appset == true).length
}

const appshownFunc = (customers) => {
    return customers.filter(customer => customer.appshown == true).length
}

const soldFunc = (customers) => {
    return customers.filter(customer => customer.sold == true).length
}
const contactFunc = (customers) => {
    return customers.filter(customer => customer.contacted == true).length
}
const add = (accumulator, a) => {
  return accumulator + a.referrals;
}
const referralFunc = (customers) => {

  return customers.reduce(add, 0);
}
const scoreFunc = (customers) => {
  return qcFunc(customers) + (appsetFunc(customers)*2)+ (referralFunc(customers)*2) + (appshownFunc(customers)*3)+ (soldFunc(customers)*5)
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'contacted', numeric: true, disablePadding: false, label: 'Contacts' },
  { id: 'qc', numeric: true, disablePadding: false, label: 'Quality Conversations' },
  { id: 'appset', numeric: true, disablePadding: false, label: 'Appt Set' },
  { id: 'appshown', numeric: true, disablePadding: false, label: 'Appt Shown' },
  { id: 'referrals', numeric: true, disablePadding: false, label: 'Referrals' },
  { id: 'sold', numeric: true, disablePadding: false, label: 'Sold' },
  { id: 'score', numeric: true, disablePadding: false, label: 'Score' },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {  order, orderBy } = this.props;
 
    return (
      <TableHead>
        <TableRow>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'center' : 'left'}
                padding={row.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}




class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'calories',
      selected: [],
      users: [],
      page: 0,
      rowsPerPage: 5,
    };
  }
 
  async componentDidMount() {
    const getCustomers = async() => {
      setTimeout(() => { 
        if(this._isMounted) {
         getCustomers()
        }
        },30000) 
    const users = await getUsers();
    const customers = await getCustomersByBootcamp();

  
   

 


    if (this.props.color == "Blue") {
      const blue = users.blueTeam.map(obj => ({ ...obj, customers: customers.filter(a => a.user.data.id === obj.id) }));
        this.setState({ 'users':blue  })
    }
    else if (this.props.color == "Red") {
      const red = users.redTeam.map(obj => ({ ...obj, customers: customers.filter(a => a.user.data.id === obj.id) }));
        this.setState({ 'users': red})
    }
    else if (this.props.color == "Black") {
      const black = users.blackTeam.map(obj => ({ ...obj, customers: customers.filter(a => a.user.data.id === obj.id) }));
        this.setState({ 'users': black })
    }
    else if (this.props.color == "Green") {
      const green = users.greenTeam.map(obj => ({ ...obj, customers: customers.filter(a => a.user.data.id === obj.id) }));
      console.log(green)
        this.setState({ 'users': green })
    }
  }
  getCustomers()
}
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleClick = (event, id) => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.users.map(n => n.id) }));
      return;
    }
    this.setState({ users: [] });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };



  render() {
    const { users, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);
    if (users.length) {
    return (
      <div>
      <div><h1 className="team-name">{this.props.color} Team
        </h1></div>
      <Paper className="paper-container">
        <div >
          <Table  aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={users.length}
            />
            <TableBody>
              {stableSort(users, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n, index) => {
              return (
                    <TableRow
                    key={n.id}
                      role="checkbox"
                      tabIndex={-1}
                    >
                      <TableCell component="th" scope="row">{n.firstName} {n.lastName}</TableCell>
                      <TableCell align="center">{contactFunc(n.customers)}</TableCell>
                      <TableCell align="center">{qcFunc(n.customers)}</TableCell>
                      <TableCell align="center">{appsetFunc(n.customers)}</TableCell>
                      <TableCell align="center">{appshownFunc(n.customers)}</TableCell>
                      <TableCell align="center">{referralFunc(n.customers)}</TableCell>
                      <TableCell align="center">{soldFunc(n.customers)}</TableCell>
                      <TableCell align="center">{scoreFunc(n.customers)}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </Paper>
      </div>
    );
        }
        else {
          return <div></div>;
      }
  }
}



const TeamDashboard = () => (
  <div className="tdashboard-bg">
  <div className="tdashboard-container">
    <EnhancedTable color={'Green'}/>
    <EnhancedTable color={'Red'}/>
    <EnhancedTable color={'Black'}/>
    <EnhancedTable color={'Blue'}/>
    </div>
  </div>
);



export default TeamDashboard;
