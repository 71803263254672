import React from 'react';
// import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import auth from '../actions/auth'
import Header from '../components/Header'

export const PublicRoute = ({
  isAuthenticated,
  component: Component,
  ...rest
}) => {
return (
    <Route {...rest} render={props => {
      
      if (auth.isAuthenticated())  
        return <Redirect to="/dashboard" />
      else {
       return (
         <div>
       <Component {...props} />
       </div>
       )
      }
         
    } 
    }
    />
  );
    }
export default PublicRoute;
