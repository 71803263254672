import React from 'react';
import { Popup } from 'react-typeform-embed';
 
class PostSurvey extends React.Component {

    componentDidMount() {

    }
  render() {
    return <Popup url="https://atntraining.typeform.com/to/mmBSM9" />;
  }
}

export default PostSurvey;