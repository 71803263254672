import React from 'react';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import auth from "../actions/auth";   
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent'; 
import {history} from '../routers/AppRouter';


const theme = createTheme({
});
const ErrorMessage = (props) => (
<div className='error-message'>
     {props.error}
  </div>
)

class Login extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
      data: {
        email: "",
        password: ""
      },
      loading: false,
      error: "",
      errorView: false
    };
  }
  componentDidMount() {
  }
  toggleError(error) {
   
        console.log(error, "toggle")
        let errorText;
      if(typeof error.message !=  "undefined"){
        if(error.message.includes('Incorrect params provided')){
          errorText = 'Failed to login'
      }
      else if(error.message.includes('email does not exist')){
          errorText = 'No user with that email found'
      }
      else if(error.message.includes('Invalid identifier or password')){
        errorText = 'Incorrect login information'
    }
      else {
        errorText = 'Unknown Error Occurred';
    }
    }
      else {
          errorText = 'Unknown Error Occurred';
      }

    this.setState({ error: errorText, errorView: true })

  }

  onChange(propertyName, event) {
    const { data } = this.state;
    data[propertyName] = event.target.value;
    this.setState({ data });
  }
  async onSubmit(e) {
    e.preventDefault();

    const {
      data: { email, password }
    } = this.state;
    this.setState({ loading: true, errorView: false });
  try {
      const result = await auth.login(email, password);
    if(result.jwt){

    }
    if (result.status !== 200){
      this.toggleError(result.response.data.error)
    }
  
  } catch (e) {
    window.alert(e);
  }
  }
  async handleForget(){
      history.push('/forgot-password')
  }

 
render() {
    return (
        <div>
        <form onSubmit={this.onSubmit.bind(this)}>
        <ThemeProvider theme={theme}>
        <div className="login-container">
          <Card className="login-box">
            <CardContent>
            <h2 className="login-title">Login</h2>
            {this.state.errorView && <ErrorMessage error={this.state.error} />}
            <TextField
                required={true}
                label="Email"
                onChange={this.onChange.bind(this, "email")}
                className="text-field"
                id="email"
                margin="normal"
                />
               <br/>
             <TextField
             required={true}
               type="password"
               label="Password"
               onChange={this.onChange.bind(this, "password")}
                className="text-field"
                margin="normal"
                id="password"
               />
             <br/>
             <Button type="submit" label="Submit" className="submit-button">Submit</Button>
             <br/>
             <div className="forgot-password-wrapper">
             <a label="Forgot Password" className="forgot-password" onClick={this.handleForget}>Forgot password</a>
             </div>
             </CardContent>
            </Card>
         </div>
         </ThemeProvider>
         </form>
      </div>
    );
  }
}
const style = {
 margin: 15,
};
export default Login;