import React from 'react';
import {Router, Switch} from 'react-router-dom';
import Login from '../components/LoginPage';
import DashboardPage from '../components/DashboardPage';
import Scoreboard from '../components/Scoreboard';
import TeamDashboard from '../components/TeamDashboard';
import NotFoundPage from '../components/NotFoundPage';
import {createBrowserHistory} from 'history';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import AddCustomer from '../components/AddCustomer';
import ResetPassword from '../components/ResetPassword';
import ForgotPassword from '../components/ForgotPassword';
import Register from '../components/Register';
import DocumentsPage from '../components/Documents';
import TeamDashboardAdmin from '../components/TeamDashboardAdmin';
import DealershipDashboard from '../components/DealershipDashboard';
import ManagerDashboard from '../components/ManagerDashboard';
import PostSurvey from '../components/PostSurvey';

export const history = createBrowserHistory();

const AppRouter = () => (
  <Router history={history}>
    <div>
      <Switch>
        <PublicRoute isAuthenticated={true} path="/" exact={true} component={Login} />
        <PublicRoute path="/reset-password" exact={true} component={ResetPassword}/>
        <PublicRoute path="/forgot-password" exact={true} component={ForgotPassword}/>
        <PublicRoute path="/register" exact={true} component={Register}/>
        <PrivateRoute path="/dashboard" exact={true}  component={DashboardPage} />
        <PrivateRoute path="/addcustomer" exact={true}  component={AddCustomer} />
        <PrivateRoute path="/scoreboard"  exact={true} component={Scoreboard} />
        <PrivateRoute path="/documents"  exact={true} component={DocumentsPage}/>
        <PrivateRoute path="/teamdashboard" exact={true} component={TeamDashboard} />
        <PrivateRoute path="/teamdashboardadmin" exact={true} component={TeamDashboardAdmin} />
        <PrivateRoute path="/dealershipdashboard" exact={true} component={DealershipDashboard} />
        <PrivateRoute path="/managerdashboard" exact={true} component={ManagerDashboard} />
        <PrivateRoute path="/PostSurvey" exact={true} component={PostSurvey} />
        <PrivateRoute component={NotFoundPage} />
      </Switch>
    </div>
  </Router>
);

export default AppRouter;
