import React from 'react';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import Button from '@mui/material/Button'; 
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent'; 
import {resetPassword} from '../actions/strapi';
import qs from 'query-string'
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {history} from '../routers/AppRouter'


const theme = createTheme({
});
const ErrorMessage = (props) => (
<div className='error-message'>
     {props.error}
  </div>
)

class ResetPassword extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
      data: {
        password: "",
        passwordConfirmation: "",
        upperCase: false,
        lowerCase: false,
        sixCharacters: false,
        numberOrSymbol: false,
        havePassword: true
      },
      loading: false,
      error: "",
      errorView: false,
      code: ""
    };
  }
  componentDidMount() {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.data.password) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('doesPasswordHaveUpper', (value) => {
        const str = value;
        const {data} = this.state;
            if (value == str.toLowerCase()) {
                data.upperCase = false;
                this.setState({data})
                return false;
            }
            data.upperCase = true;
            this.setState({data})
            return true;
        });
        ValidatorForm.addValidationRule('doesPasswordHaveLower', (value) => {
        const str = value;
        const {data} = this.state;
            if (value == str.toUpperCase()) {
                data.lowerCase = false;
                this.setState({data})
                return false;
            }
            data.lowerCase = true;
            this.setState({data})
            return true;
        });
        ValidatorForm.addValidationRule('doesPasswordHaveNumber', (value) => {
        function hasNumber(string) {
             return /\d/.test(string);
         }
         function hasSymbol(string){
            return /[\W\_]/.test(string); 
         }
        const {data} = this.state;
            if (hasNumber(value)) {
                data.numberOrSymbol = true;
                this.setState({data})
                return true;
            }
             else if (hasSymbol(value)) {
                data.numberOrSymbol = true;
                this.setState({data})
                return true;
            }

            data.numberOrSymbol = false;
            this.setState({data})
            return false;
        });
          ValidatorForm.addValidationRule('doesPasswordHaveSix', (value) => {
        const {data} = this.state;
            if (value.length < 6) {
                data.sixCharacters = false;
                this.setState({data})
                return false;
            }
            data.sixCharacters = true;
            this.setState({data})
            return true;
        });
        ValidatorForm.addValidationRule('doesPasswordHavePassword', (value) => {
        const str = value;
        const {data} = this.state;
            if (str.toUpperCase().includes("PASSWORD")) {
                data.havePassword = false;
                this.setState({data})
                return false;
            }
            data.havePassword = true;
            this.setState({data})
            return true;
        });
    const parsed = qs.parse(this.props.location.search);
    this.setState({ code:parsed.code})
  }
  componentWillUnmount() {
    ValidatorForm.removeValidationRule('isPasswordMatch');
    ValidatorForm.removeValidationRule('doesPasswordHaveUpper');
    ValidatorForm.removeValidationRule('doesPasswordHaveLower');
    ValidatorForm.removeValidationRule('doesPasswordHaveSix');
    ValidatorForm.removeValidationRule('doesPasswordHaveNumber');
    ValidatorForm.removeValidationRule('doesPasswordHavePassword');
  }
  toggleError(error) {
      let errorText;
      console.log(error.message)
      if(typeof error.message !=  "undefined"){
        if(error.message.includes('Incorrect params provided')){
          errorText = 'Failed to reset'
      }
      else if(error.message.includes('email does not exist')){
          errorText = 'No user with that email found'
      }
      else {
        errorText = 'Unknown Error Occurred';
    }
    }
      else {
          errorText = 'Unknown Error Occurred';
      }
    this.setState({ error: errorText, errorView: true })
  }

  onChange(propertyName, event) {
    const { data } = this.state;
    data[propertyName] = event.target.value;
    this.setState({ data });
  }
  async onSubmit(e) {
    e.preventDefault();
    const {
      data: { password, passwordConfirmation }, code
    } = this.state;
    this.setState({ loading: true, errorView: false });
  try {
      const result = await resetPassword(code, password, passwordConfirmation);
    history.push('/')
  
  } catch (e) {
    this.toggleError(e);
  }
  }

 
render() {
    return (
        <div>
        <ThemeProvider theme={theme}>
        <ValidatorForm className="reset-password-form" onSubmit={this.onSubmit.bind(this)}>
        <div className="login-container">
          <Card className="login-box">
            <CardContent>
            <h2 className="login-title">Reset Password</h2>
            {this.state.errorView && <ErrorMessage error={this.state.error} />}
            <div className="password-container">
               <div className="password-requirements">
               <div className="passsword-requirements-list">{this.state.data.lowerCase ? <span className="checkmark">&#10003;</span>:<span className="redx">&#10005;</span>} At least 1 lowercase letter</div>
               <div className="passsword-requirements-list">{this.state.data.upperCase ? <span className="checkmark">&#10003;</span>:<span className="redx">&#10005;</span>} At least 1 uppercase letter</div>
               <div className="passsword-requirements-list">{this.state.data.numberOrSymbol ? <span className="checkmark">&#10003;</span>:<span className="redx">&#10005;</span>} Contain 1 number or symbol</div>
               <div className="passsword-requirements-list">{this.state.data.sixCharacters? <span className="checkmark">&#10003;</span>:<span className="redx">&#10005;</span>} At least 6 characters long</div>
               <div className="passsword-requirements-list">{this.state.data.havePassword ? <span className="checkmark">&#10003;</span>:<span className="redx">&#10005;</span>} Cannnot contain "password"</div>
               </div>
            <TextValidator
             type="password"
             validators={['doesPasswordHaveUpper', 'doesPasswordHaveLower','doesPasswordHaveSix','doesPasswordHaveNumber','doesPasswordHavePassword', 'required']}
                label="New Password"
                errorMessages={['', '', '', '','', 'This field is required']}
                onChange={this.onChange.bind(this, "password")}
                value={this.state.data.password}
                className="validator-field"
                />
                </div>
               <br/>
             <TextValidator
             validators={['isPasswordMatch', 'required']}
             errorMessages={[`Password don't match`, 'This field is required']}
               type="password"
               label="Confirm Password"
               onChange={this.onChange.bind(this, "passwordConfirmation")}
               value={this.state.data.passwordConfirmation}
                className="validator-field"
               />
             <br/>
             <Button label="Submit" className="submit-button" type="submit">Submit</Button>
             </CardContent>
            </Card>
         </div>
         </ValidatorForm>
         </ThemeProvider>
      </div>
    );
  }
}
export default ResetPassword;