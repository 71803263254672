import React, { Component } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Outbound from './20-outbound-calls.pdf';
import TenSteps from './10-steps-quality.pdf';
import PhoneFundamentals from './chapter-2-stuker.pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


class PDFDocument extends Component {
    constructor (props){
        super(props)
  this.state = {
    numPages: null,
    pageNumber: 1,
    width: null,
    scale: 1,
  }

    }
   
componentDidMount(){
    this.setDivSize()
    // window.addEventListener("resize")
}
  onDocumentLoadSuccess = (document) => {
    const { numPages } = document;
    this.setState({
      numPages,
      pageNumber: 1,
    });
  };

  setDivSize = () => {
 const reportWindowSize = () => {
     let width;
     if (window.innerWidth < 1000){
         width  = window.innerWidth;
     }
     else{
         width = 1000;
     }
 this.setState({width})

}
reportWindowSize();
// window.onresize = reportWindowSize;
  }
  

  changePage = offset => this.setState(prevState => ({
    pageNumber: prevState.pageNumber + offset,
    scale: 1
  }));
  changeZoom = offset => this.setState(prevState => ({
    scale: prevState.scale + offset,
  }));

  previousPage = () => this.changePage(-1);

  nextPage = () => this.changePage(1);

  zoomOut = () => this.changeZoom(-0.25);

  zoomIn = () => this.changeZoom(+.25);

  returnZoom = () => this.setState({ scale: 1});

  render() {
    const { numPages, pageNumber, scale } = this.state;

    return (
      <React.Fragment>
        <Document
          file={this.props.file}
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
          <Page width={this.state.width} renderTextLayer={false} pageNumber={pageNumber} scale={scale} />
        </Document>
        <div className="pdf-nav">
          <p>
            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
          </p>
          <div className="button-row">
          <button
            type="button"
            disabled={pageNumber <= 1}
            onClick={this.previousPage}
          >
           <i className="arrow left"></i>
          </button>
          <button
          className="zoom-icons minus"
            type="button"
            onClick={this.zoomOut}
          >
          </button>
          <button
            className="zoom-icons plus"
            type="button"
            onClick={this.zoomIn}
          >
          </button>
          <button
            type="button"
            disabled={pageNumber >= numPages}
            onClick={this.nextPage}
          >
            <i className="arrow right"></i>
          </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

class DocumentsPage extends Component {
    constructor (props){
        super(props)
      this.state = {
        file: "",
        fileStatus: false
      }
    }
outboundCalls = () => this.setState({ file:Outbound, fileStatus: true});
tenSteps = () => this.setState({ file: TenSteps, fileStatus: true});
phoneFundamentals = () => this.setState({ file: PhoneFundamentals, fileStatus: true});
goBack = () => this.setState({file: "", fileStatus: false});

render() {

    return (
    <div>
    { !this.state.fileStatus ?
      <div className="document-list">
      <div onClick={this.outboundCalls} className="document-item">20 Outbound Calls</div>
      <div onClick={this.tenSteps} className="document-item">10 Steps to a Quality Conversation</div>
      <div onClick={this.phoneFundamentals} className="document-item">Phone Fundamentals</div>
    </div>
    :
    <div>
    <div className="pdf-back-wrapper" onClick={this.goBack}><div className="pdf-back-button">Back</div></div>
      <PDFDocument file={this.state.file}/>
      </div>
    }
    </div>
   
    );
  }
}
export default DocumentsPage;