import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import * as Sentry from '@sentry/browser';
import App from './App';
import * as serviceWorker from './serviceWorker';

Sentry.init({dsn: "https://e5929577d7ce44b6b7be0877cf6b32df@sentry.io/1724514"});


const container = document.getElementById('root');
const root = createRoot(container); 
root.render(<App />);

        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: https://bit.ly/CRA-PWA
        serviceWorker.unregister();